import React, { useEffect, useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { useFormik, FormikProvider } from "formik";
import cardService from "../../services/card.service";
import InputGroup from "./InputGroup";
import authService from "../../services/auth.service";
import CheckBox from "./CheckBox";

function NewCardForm(props) {
  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const user = authService.getCurrentUser().id;
  const categoryArr = [
    "botox",
    "boutique",
    "coffee & beverage",
    "facial",
    "hair",
    "laser",
    "lashes & brows",
    "massages & body wellness",
    "nails",
    "other",
    "wax & tan",
  ];
  const initialValues = props.initialValues
    ? props.initialValues
    : {
        business_name: "",
        contact_name: "",
        phone: "",
        email: "",
        website: "",
        logo: "",
        categories: [],
        about: "",
        services: "",
      };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setSubmitDisabled(true);
      let formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      formData.append("board", user);

      if (props.initialValues) {
        formData.append("cardId", props.initialValues.id);
        cardService.updateCard(formData).then((res) => {
          props.show();
        });
      } else {
        cardService.creatCard(formData).then((res) => {
          setSuccess(true);
          formik.handleReset();
          setTimeout(() => {
            setSuccess(false);
            setSubmitDisabled(false);
          }, 2000);
        });
      }
    },
  });
  const handleFile = (e) => {
    formik.setFieldValue("logo", e.currentTarget.files[0]);
  };
  console.log(formik.values);
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="business_name"
              label="Business Name"
            />
          </Col>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="contact_name"
              label="Contact Name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="phone"
              label="Phone Number"
            />
          </Col>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="email"
              label="Email"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="website"
              label="Website"
            />
          </Col>
          <Col>
            <div className="d-flex align-items-center">
              <InputGroup
                errors={formik.errors}
                touched={formik.touched}
                type="file"
                file={handleFile}
                name="logo"
                label="Business Card Logo"
              />
              {props.initialValues ? (
                <img
                  class="new-card-preview-img"
                  src={props.initialValues.logo}
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="about"
              label="About"
            />
          </Col>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="services"
              label="Services"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CheckBox name="categories" values={categoryArr} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <button
                disabled={submitDisabled}
                type="submit"
                className="btn new-card-btn btn-standard"
              >
                {props.initialValues ? "update" : "create"}
              </button>
              {success ? (
                <Alert className="mt-3 w-100" variant="success">
                  submitted succesfully
                </Alert>
              ) : null}
            </div>
          </Col>
          <Col></Col>
        </Row>
      </form>
    </FormikProvider>
  );
}

export default NewCardForm;
