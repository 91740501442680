import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PageContainer from "../../components/containers/PageContainer";
import PageHeader from "../../components/PageHeader";
import AssignCardsTable from "../../components/tables/AssignCardsTable";
import AllCardsTable from "../../components/tables/AllCardsTable";
import NewCardForm from "../../components/forms/NewCardForm";
import boardService from "../../services/board.service";
import suiteService from "../../services/suite.service";
import Select from "react-select";
import { swapPlaces } from "../../services/helper";
import authService from "../../services/auth.service";
function AllCards() {
  let user = authService.getCurrentUser()
    ? authService.getCurrentUser().id
    : null;

  const [cardList, setCardList] = useState([]);
  const [currentBoard, setCurrentBoard] = useState("");
  const [allBoards, setAllBoards] = useState([]);
  const [suites, setSuites] = useState([]);
  const [view, setView] = useState("assigned");
  const [nameMap, setNameMap] = useState({});
  const [selectedCard, setSelectedCard] = useState({});
  const [selectedDir, setSelectedDir] = useState("");

  useEffect(() => {
    boardService.getAllBoards({ id: user }).then((res) => {
      setAllBoards(res.data);
    });
  }, []);

  useEffect(() => {
    if (currentBoard) {
      boardService.boardInfo({ id: currentBoard }).then((res) => {
        setCardList(res.data);

        let mapObj = {};
        res.data.cards.map((c) => {
          mapObj[c.id] = c.business_name;
        });
        setNameMap(mapObj);
      });
      suiteService.getSuitesByBoard({ boardId: currentBoard }).then((res) => {
        setSuites(res.data);
      });
    }
  }, [currentBoard, view]);
  const handleDirChange = (e) => {
    setCurrentBoard(e.value);
  };
  const handleSuiteChange = (suite) => {
    let swapped = swapPlaces(suites, suite);
    setSuites(swapped);
    suiteService.updateSuite({ ...suite, board: currentBoard }).then((res) => {
      console.log("suite updated");
    });
  };
  const handleEdit = (card) => {
    let tempCard = { ...card };
    delete tempCard.suiteId;
    tempCard.categories = card.categories.map((cat) => cat.name.toLowerCase());

    setSelectedCard(tempCard);
    setView("edit");
  };
  const handleShow = () => {
    setView("cards");
  };
  const generatePageHeader = () => {
    switch (view) {
      case "assigned":
        return (
          <PageHeader
            text="Assigned Cards"
            button="view all cards"
            buttonAction={() => setView("cards")}
          />
        );
      case "cards":
        return (
          <PageHeader
            text="AllCards"
            button="assign cards"
            buttonAction={() => setView("assigned")}
          />
        );
      case "edit":
        return (
          <PageHeader
            text="Edit Card"
            button="back"
            buttonAction={() => setView("cards")}
          />
        );
      default:
        return (
          <PageHeader
            text="Assign Cards"
            button="view all cards"
            buttonAction={() => setView("cards")}
          />
        );
    }
  };

  return (
    <PageContainer>
      <Container>
        <div className="inner-container">
          {generatePageHeader()}
          <Select
            onChange={handleDirChange}
            placeholder="Select a Directory..."
            options={allBoards.map((board) => ({
              label: board.board_name,
              value: board.id,
            }))}
          />
          {view == "assigned" ? (
            <AssignCardsTable
              nameMap={nameMap}
              handleChange={handleSuiteChange}
              suites={suites}
              cards={cardList.cards}
            />
          ) : view == "cards" ? (
            <AllCardsTable edit={handleEdit} cards={cardList.cards} />
          ) : (
            <NewCardForm show={handleShow} initialValues={selectedCard} />
          )}
        </div>
      </Container>
    </PageContainer>
  );
}

export default AllCards;
