import React from "react";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/containers/PageContainer";
import { Container } from "react-bootstrap";
import NewUserForm from "../../components/forms/NewUserForm";
function NewUser() {
  return (
    <PageContainer>
      <Container>
        <div className="inner-container">
          <PageHeader text="Register New User" />
          <NewUserForm />
        </div>
      </Container>
    </PageContainer>
  );
}

export default NewUser;
