import React from "react";
import { Container } from "react-bootstrap";
import NewCardForm from "../../components/forms/NewCardForm";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/containers/PageContainer";
import "./dash.css";
function NewCard() {
  return (
    <PageContainer>
      <Container>
        <div className="inner-container">
          <PageHeader text="New Card" />
          <NewCardForm />
        </div>
      </Container>
    </PageContainer>
  );
}

export default NewCard;
