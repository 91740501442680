import React, { useState } from "react";
import * as yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { Row, Col, Alert } from "react-bootstrap";
import InputGroup from "./InputGroup";
import authService from "../../services/auth.service";

function NewUserForm() {
  const stateManager = {
    start: "5px",
    end: "20px",
    speed: "fast",
  };
  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      authService.createUser(values.username, values.password).then(() => {
        setSuccess(true);
        formik.handleReset();
        setTimeout(() => {
          setSuccess(false);
          setSubmitDisabled(false);
        }, 2000);
      });
    },
  });
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="username"
              label="Username"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <InputGroup
              errors={formik.errors}
              touched={formik.touched}
              name="password"
              label="Password"
              type="password"
            />
          </Col>
          <Col></Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group">
              <button
                disabled={submitDisabled}
                className="w-25 btn btn-standard"
                type="submit"
              >
                submit
              </button>
              {success ? (
                <Alert className="mt-3 w-25" variant="success">
                  submitted succesfully
                </Alert>
              ) : null}
            </div>
          </Col>
        </Row>
      </form>
    </FormikProvider>
  );
}

export default NewUserForm;
