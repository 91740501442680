import React from "react";

function ImageSlider() {
  return (
    <div className="slideshow">
      <div className="images">
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
        <img
          class="scroller"
          src="https://projectcity.com/digitalDirectory/images/salonsbyjc-CA-7041/Copy_of_Copy_of_Copy_of_Copy_of_Copy_of_Untitled_(5).png"
        />
      </div>
    </div>
  );
}

export default ImageSlider;
