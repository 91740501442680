import axios from "axios";
import authHeader from "./auth.header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/auth/"
  : "https://adiuvo-digital-display.herokuapp.com/api/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API + "signin", {
        username,
        password,
      })
      .then((res) => {
        if (res.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
        return res.data;
      });
  }
  createUser(username, password) {
    return axios.post(API + "signup", {
      username,
      password,
    });
  }
  logout() {
    localStorage.removeItem("user");
  }
  getCurrentUser() {
    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch {
      localStorage.clear();
    }
  }
  deleteUser(id) {
    return axios.post(API + "delete-user", id, { headers: authHeader() });
  }
  updatePassword(id) {
    return axios.post(API + "update-password", id, { headers: authHeader() });
  }
  instaCode(data) {
    return axios.post(API + "insta-code", data, { headers: authHeader() });
  }
}
export default new AuthService();
