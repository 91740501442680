import React, { useEffect, useState } from "react";
import "./directory.css";
import { useParams } from "react-router-dom";
import Cards from "../../components/directory/Cards";
import fp from "./fp-01.png";
import Contact from "../../components/directory/Contact";
import Video from "../../components/directory/Video";
import Featured from "../../components/directory/Featured";
import Footer from "../../components/directory/Footer";
import boardService from "../../services/board.service";
import ScreenSaver from "../../components/directory/ScreenSaver";
import Single from "../../components/directory/Single";

function Directory() {
  const params = useParams();
  const [board, setBoard] = useState({});
  const [filter, setFilter] = useState("all");
  const [ssTime, setSsTime] = useState(30000);
  const [ss, setSs] = useState(false);
  const [boardState, setBoardState] = useState("main");
  const [singleBoard, setSingleBoard] = useState({});

  useEffect(
    function grabBoardData() {
      if (params) {
        boardService.getBoardByName({ board_name: params.dir }).then((res) => {
          setBoard(res.data);
          console.log(res.data);
        });
      }
      setTimeout(() => {});
    },
    [params]
  );

  const handleSingle = (card) => {
    setSingleBoard(card);
    setBoardState("single");
  };
  const handleBack = () => {
    setSingleBoard({});
    setBoardState("main");
  };
  const handleFilter = (name) => {
    setFilter(name);
  };

  return (
    <div className="dir-page-container">
      {ss ? (
        <ScreenSaver
          phone={board.contact_phone}
          email={board.contact_email}
          name={board.contact_name}
          location={board.board_name}
        />
      ) : null}

      {boardState == "main" ? (
        <>
          <Cards
            handleSingle={handleSingle}
            filter={filter}
            cards={board.cards}
          />
          <div className="info-container main-info">
            <Contact
              phone={board.contact_phone}
              email={board.contact_email}
              name={board.contact_name}
            />
            <Video
              link={`https://www.youtube.com/embed/${board.playlist_key}`}
            />
            <div className="map-container">
              <img src={fp} />
            </div>
            <Featured possibleCards={board.cards} />
          </div>
          <Footer handleFilter={handleFilter} />
        </>
      ) : (
        <Single
          handleBack={handleBack}
          playlist={`https://www.youtube.com/embed/${board.playlist_key}`}
          card={singleBoard}
          insta={board.instagram_long_term_code}
        />
      )}
    </div>
  );
}
export default Directory;
