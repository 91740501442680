import "./App.css";
import ReactDOM from "react-dom/client";
import MyRoutes from "./services/MyRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./components/Navigation";
import { useEffect, useState } from "react";
import authService from "./services/auth.service";
function App() {
  const [showNav, setHideNav] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  function logout() {
    authService.logout();
    navigate("/login");
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      location.pathname.includes("login") ||
      location.pathname.includes("directory")
    ) {
      setHideNav(false);
    } else {
      setHideNav(true);
    }
    if (user || window.location.pathname.includes("directory")) {
      if (user) {
        const decodedJwt = parseJwt(user.accessToken);

        if (!decodedJwt || decodedJwt.exp * 1000 < Date.now()) {
          logout();
        }
      }
    } else if (window.location.pathname != "/login") {
      window.location.href = "/login";
    }
  }, [location]);
  return (
    <>
      {showNav ? <Navigation /> : null}
      <MyRoutes />
    </>
  );
}
export default App;
