import React from "react";
import { Container } from "react-bootstrap";
import NewBoardForm from "../../components/forms/NewBoardForm";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/containers/PageContainer";
function NewBoard() {
  return (
    <PageContainer>
      <Container>
        <div className="inner-container">
          <PageHeader text="New Board" />
          <NewBoardForm />
        </div>
      </Container>
    </PageContainer>
  );
}

export default NewBoard;
