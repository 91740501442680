import axios from "axios";
import authHeader from "./auth.header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class CardService {
  creatCard(data) {
    return axios.post(API + "create-card", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }
  updateCard(data) {
    return axios.post(API + "update-card", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
export default new CardService();
