import React, { useState, useEffect } from "react";
import facial from "./images/facial.png";
import { useNavigate, useLocation } from "react-router-dom";
function Cards(props) {
  const [cardList, setCardList] = useState([]);
  const [multiplier, setMultiplier] = useState(1.0);
  const generateCards = (num) => {
    if (num <= 25) {
      setMultiplier(1.75);
    } else if (num <= 49 && num > 25) {
      setMultiplier(1.5);
    } else if (num <= 63 && num > 49) {
      setMultiplier(1.25);
    } else if (num <= 80 && num > 63) {
      setMultiplier(1.11);
    } else if (num <= 100 && num > 80) {
      setMultiplier(1.0);
    }
    let tempArr = [];
    for (let i = 1; i <= num; i++) {
      tempArr.push("hello");
    }
    setCardList(tempArr);
  };
  const callMultiplier = (array) => {
    const num = array.length;
    if (num < 10) {
      setMultiplier(2.0);
    } else if (num <= 25 && num > 10) {
      setMultiplier(1.75);
    } else if (num <= 49 && num > 25) {
      setMultiplier(1.5);
    } else if (num <= 63 && num > 49) {
      setMultiplier(1.25);
    } else if (num <= 80 && num > 63) {
      setMultiplier(1.11);
    } else if (num <= 100 && num > 80) {
      setMultiplier(1.0);
    }
  };
  useEffect(() => {
    if (props.cards) {
      setCardList(props.cards);
      callMultiplier(props.cards);
    }
  }, [props.cards]);

  return (
    <div className="d-flex justify-content-center">
      <div className="card-list-container">
        {cardList.map((card, i) => {
          let categories = card.categories.map((e) => e.name.toLowerCase());
          if (
            categories.includes(props.filter.toLowerCase()) ||
            props.filter == "all"
          ) {
            return (
              <div
                onClick={() => props.handleSingle(card)}
                key={i}
                style={{ width: 192 * multiplier, height: 47 * multiplier }}
                className=" card-box"
              >
                <img
                  style={{ width: 47 * multiplier, height: 47 * multiplier }}
                  className="card-img-box"
                  src={card.logo ? card.logo : facial}
                ></img>
                <div className="card-text-container">
                  <p>{card.business_name}</p>
                  <p>{card.contact_name}</p>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Cards;
