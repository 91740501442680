import React from "react";

function PageHeader(props) {
  return (
    <header className="d-flex justify-content-between align-items-center">
      <h2 className="sub-header">{props.text}</h2>
      {props.button ? (
        <button
          onClick={props.buttonAction}
          className="ms-auto btn-standard btn btn-sm"
        >
          {props.button}
        </button>
      ) : null}
    </header>
  );
}

export default PageHeader;
