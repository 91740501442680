import React, { useEffect, useState } from "react";
import QRCode from "./QRCode";
import facial from "./images/facial.png";
import {
  FaUser,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaArrowCircleLeft,
} from "react-icons/fa";
import Video from "./Video";
import suiteService from "../../services/suite.service";
import tpService from "../../services/tp.service";
function Single(props) {
  const [instaImages, setInstaImages] = useState([]);
  const [sitemapUrl, setSiteMapUrl] = useState("");
  useEffect(function grabSuiteSiteMap() {
    suiteService.getSuiteMap({ id: props.card.suiteId }).then((res) => {
      setSiteMapUrl(res.data.sitemap_image);
    });
  }, []);
  useEffect(function getInstaImages() {
    tpService.fetchInstagramImages(props.insta).then((res) => {
      setInstaImages(res.data.data);
    });
  }, []);
  const business = props.card;
  console.log(instaImages);
  return (
    <div className="single  text-light w-100">
      <div className="px-5 single-top-row d-flex justify-content-between align-items-center">
        <img
          className="single-card-image"
          src={business.logo ? business.logo : facial}
        ></img>
        <div className=" single-contact-details">
          <h2 className="single-header">{business.business_name}</h2>
          <ul class="single-list unstyled-list">
            <li>
              <FaUser />
              <span className="mx-3">{business.contact_name}</span>
            </li>
            <li>
              <FaPhone />
              <span className="mx-3">{business.phone}</span>
            </li>

            <li>
              <FaEnvelope />
              <span className="mx-3">{business.email}</span>
            </li>
            <li>
              <FaGlobe />
              <span className="mx-3">{business.website}</span>
            </li>
          </ul>
        </div>
        <QRCode value={business.website} />
      </div>
      <div className="d-flex">
        <div className="p-5 w-50 single-about">
          <h2 className="single-header ">About</h2>
          <p>{business.about}</p>
        </div>
        <div className="p-5 w-50 single-services">
          <h2 className="single-header">Services</h2>
          <p>{business.services}</p>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="p-5">
          <Video link={props.playlist} />
        </div>
        <div className="p-5">
          <img src={sitemapUrl} />
        </div>
        <div className="p-5">
          <img className="single-insta-image" src={instaImages[0].media_url} />
        </div>
      </div>
      <FaArrowCircleLeft onClick={props.handleBack} class="back-arrow" />
    </div>
  );
}

export default Single;
