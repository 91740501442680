import React, { useEffect, useState } from "react";
import userService from "../../services/user.service";
import { Container } from "react-bootstrap";
import AllUsersTable from "../../components/tables/AllUsersTable";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/containers/PageContainer";
import authService from "../../services/auth.service";
import ConfirmDelete from "../../components/modals/ConfirmDelete";
import NewPassword from "../../components/modals/NewPassword";
function AllUsers() {
  const [userList, setUserList] = useState([]);
  const [potentialDelete, setPotentialDelete] = useState("");
  const [potentialPassChange, setPotentialPassChange] = useState("");

  const [showDel, setShowDel] = useState(false);
  const handleCloseDel = () => {
    setPotentialDelete("");
    setShowDel(false);
  };
  const handleShowDel = () => setShowDel(true);

  const [showPass, setShowPass] = useState(false);
  const handleClosePass = () => {
    setPotentialPassChange("");
    setShowPass(false);
  };
  const handleShowPass = () => setShowPass(true);

  useEffect(() => {
    userService.getUsers().then((res) => {
      setUserList(res.data);
    });
  }, []);
  const handleConfirmDelete = (id) => {
    handleShowDel();
    setPotentialDelete(id);
  };
  const handleConfirmPass = (username, id) => {
    handleShowPass();
    setPotentialPassChange({ username, id });
  };

  const confirmPass = (newPass) => {
    if (potentialPassChange) {
      authService.updatePassword({ uid: potentialPassChange.id, newPass });
    }
  };

  const confirm = () => {
    if (potentialDelete) {
      authService.deleteUser({ id: potentialDelete }).then(() => {
        let temp = [...userList];
        temp = temp.filter((e) => e.id != potentialDelete);
        setUserList(temp);
        setPotentialDelete("");
        handleCloseDel();
      });
    }
  };
  return (
    <PageContainer>
      <Container>
        <div className="inner-container">
          <h1>{potentialDelete}</h1>
          <h1>{potentialPassChange.id}</h1>
          <ConfirmDelete
            handleClose={handleCloseDel}
            confirm={confirm}
            show={showDel}
          />
          <NewPassword
            user={potentialPassChange.username}
            handleClose={handleClosePass}
            show={showPass}
            confirm={confirmPass}
          />
          <PageHeader text="All Users" />
          <AllUsersTable
            handlePass={handleConfirmPass}
            handleDelete={handleConfirmDelete}
            data={userList}
          />
        </div>
      </Container>
    </PageContainer>
  );
}

export default AllUsers;
