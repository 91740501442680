import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";
import Select from "react-select";
function AssignCardsTable(props) {
  const opts = props.cards
    ? props.cards.map((card) => {
        return { label: card.business_name, value: card.id };
      })
    : [];

  const getValues = (arr, id) => {
    // first parameter is all cards
    // second paramter is spot id
    let found = props.cards.find((e) => e.id == id);

    if (found != undefined) {
      return { label: found.business_name, value: found.id };
    } else {
    }
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Suite #</th>
          <th className="all-card-header">Stylist 1</th>
          <th className="all-card-header">Stylist 2</th>
          <th className="all-card-header">Stylist 3</th>
        </tr>
      </thead>
      <tbody>
        {props.suites.map((e) => {
          return (
            <tr key={e.id}>
              <td>{e.suite_number}</td>
              <td>
                <Select
                  onChange={(sv) =>
                    props.handleChange({
                      suite_number: e.suite_number,
                      company: sv.value,
                      spot: "spot_1",
                    })
                  }
                  value={{ label: props.nameMap[e.spot_1], value: e.spot_1 }}
                  options={opts}
                />
              </td>
              <td>
                <Select
                  onChange={(sv) =>
                    props.handleChange({
                      suite_number: e.suite_number,
                      company: sv.value,
                      spot: "spot_2",
                    })
                  }
                  value={{ label: props.nameMap[e.spot_2], value: e.spot_2 }}
                  options={opts}
                />
              </td>
              <td>
                <Select
                  onChange={(sv) =>
                    props.handleChange({
                      suite_number: e.suite_number,
                      company: sv.value,
                      spot: "spot_3",
                    })
                  }
                  value={{ label: props.nameMap[e.spot_3], value: e.spot_3 }}
                  options={opts}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default AssignCardsTable;
