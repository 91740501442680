import React, { useState, useEffect } from "react";
import { filterImages } from "../../services/constants";
import { titleCase } from "../../services/helper";
import { FaUser, FaPhone, FaEnvelope } from "react-icons/fa";
function Featured(props) {
  const [current, setCurrent] = useState("");
  const [possibleCards, setPossibleCards] = useState([]);

  const pickNew = () => {
    // const arrCopy = [...possibleCards];
    const random = [...possibleCards].sort(() => 0.5 - Math.random())[0];
    setCurrent(random);
  };
  useEffect(() => {
    setPossibleCards(props.possibleCards);
  }, [props.possibleCards]);
  useEffect(() => {
    console.log(possibleCards);
    if (possibleCards) {
      if (possibleCards.length > 0) {
        let interval = setInterval(() => {
          pickNew();
        }, 5000);

        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [possibleCards]);
  return (
    <div className="featured-cards">
      <div className=" card-box">
        <img
          className="featured-img"
          src={current.logo ? current.logo : filterImages[0].image}
        ></img>
        <div className="card-text-container featured-text">
          <p>{current.business_name}</p>
          <p>{current.contact_name}</p>
        </div>
      </div>
    </div>
  );
}

export default Featured;
