import React from "react";
import { Modal } from "react-bootstrap";
function ConfirmDelete(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <div className="generic-modal">
        <h1 className="generic-modal-header">Are you sure?</h1>
        <hr />
        <p>
          <strong>This action cannot be undone</strong>
        </p>
        <button onClick={props.confirm} className="btn btn-standard">
          confirm
        </button>
      </div>
    </Modal>
  );
}

export default ConfirmDelete;
