import axios from "axios";
import authHeader from "./auth.header";
import { useNavigate } from "react-router-dom";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class CardService {
  getUsers(data) {
    return axios.get(API + "users", { headers: authHeader() });
  }
}
export default new CardService();
