import React from "react";
import ImageSlider from "./ImageSlider";
import Video from "./Video";
import Contact from "./Contact";
import Featured from "./Featured";
import fp from "./images/fp-01.png";
function ScreenSaver(props) {
  return (
    <div className="screensaver">
      <ImageSlider />
      <div className="hero">
        <div className="hero-text">
          <div className="un-diamond">
            <div className="top-text">
              <h2>Welcome To</h2>
            </div>
            <div className="mid-text">{props.location}</div>
            <div className="bottom-text">{"Tap the screen to begin!"}</div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="info-container">
          <Contact phone={props.phone} email={props.email} name={props.name} />
          <Video link={`https://www.youtube.com/embed/${props.link}`} />
          <div className="map-container">
            <img src={fp} />
          </div>
          <Featured arr={[1, 2, 3, 4]} />
        </div>
      </div>
    </div>
  );
}

export default ScreenSaver;
