import React from "react";
import { FaPhone, FaUser, FaEnvelope } from "react-icons/fa";
function Contact(props) {
  return (
    <div className="contact-container">
      <h1 className="info-header alumni">
        For info about renting suites contact:
      </h1>
      <ul className="list-unstyled grid-list">
        <li>
          <FaUser /> {props.name}
        </li>
        <li>
          <FaPhone /> {props.phone}
        </li>
        <li>
          <FaEnvelope /> {props.email}
        </li>
      </ul>
    </div>
  );
}

export default Contact;
