import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/containers/PageContainer";
import boardService from "../../services/board.service";
import AllBoardsTable from "../../components/tables/AllBoardsTable";
import NewBoardForm from "../../components/forms/NewBoardForm";
import authService from "../../services/auth.service";
import MultiUpload from "../../components/forms/MultiUpload";
function AllBoards() {
  const [boardData, setBoardData] = useState([]);
  const [editingBoard, setEditingBoard] = useState(false);
  const [currBoardVal, setcurrBoardVal] = useState({});
  const [currBoardId, setCurrBoardId] = useState("");
  const [sitemaps, setSiteMaps] = useState([]);
  const [sMURL, setSMURL] = useState([]);

  const handleEdit = (id) => {
    setCurrBoardId(id);
    boardService.getBoardById({ id }).then((res) => {
      console.log(res.data);
      const tempObj = {
        boardName: res.data.board_name,
        contactName: res.data.contact_name,
        phone: res.data.contact_phone,
        email: res.data.contact_email,
        numOfSuites: res.data.number_of_suites,
        playListkey: res.data.playlist_key,
        instagram_long_term_code: res.data.instagram_long_term_code,
        cardArea: "",
        accentColor: "",
      };
      if (res.data.user) {
        tempObj.userAccess = res.data.user.id;
      }
      setcurrBoardVal(tempObj);
      setEditingBoard(true);
    });
  };
  const handleBack = () => {
    setEditingBoard(false);
  };
  const handleMapUpload = (e) => {
    setSiteMaps([...e.target.files]);
  };
  const handleMultiSubmit = () => {
    const formData = new FormData();
    formData.append("boardId", currBoardId);
    for (const image of sitemaps) {
      formData.append("files", image);
    }
    boardService.multiUpload(formData).then(() => {
      console.log("uploaded forms");
    });
  };
  useEffect(() => {
    boardService
      .getAllBoards({ id: authService.getCurrentUser().id })
      .then((res) => {
        setBoardData(res.data);
      });
  }, []);
  useEffect(() => {
    if (sitemaps.length < 1) return;
    const newSMURL = [];
    sitemaps.forEach((i) => newSMURL.push(URL.createObjectURL(i)));
    setSMURL(newSMURL);
  }, [sitemaps]);

  return (
    <PageContainer>
      <Container>
        <div className="inner-container">
          {editingBoard ? (
            <>
              <PageHeader text="Edit Board" />
              <button onClick={handleBack} className="btn btn-standard">
                back
              </button>
              <NewBoardForm id={currBoardId} initialValues={currBoardVal} />
            </>
          ) : (
            <>
              <PageHeader text="All Boards" />
              <AllBoardsTable handleEdit={handleEdit} data={boardData} />
            </>
          )}
        </div>
        <button className="standard-btn btn">create</button>

        {editingBoard ? (
          <div className="inner-container flex-column  py-5">
            <PageHeader text="Sitemap Images" />
            <MultiUpload handleChange={handleMapUpload} /> <br />
            {sMURL.length > 0 ? (
              <button
                onClick={handleMultiSubmit}
                className="my-3 btn btn-standard"
              >
                upload
              </button>
            ) : null}
            <div className="multi-images">
              {sMURL.map((i) => (
                <img className="multi-image" src={i} />
              ))}
            </div>
          </div>
        ) : null}
      </Container>
    </PageContainer>
  );
}

export default AllBoards;
