export const swapPlaces = (array, newInfo) => {
  let { company, suite_number, spot } = newInfo;
  let objArrayCopy = [...array];
  return objArrayCopy
    .map((e) => {
      let toReturn = { ...e };
      if (e.spot_1 == company) {
        toReturn.spot_1 = null;
      }
      if (e.spot_2 == company) {
        toReturn.spot_2 = null;
      }
      if (e.spot_3 == company) {
        toReturn.spot_3 = null;
      }
      return toReturn;
    })
    .map((i) => {
      if (i.suite_number == suite_number) {
        return { ...i, [spot]: company };
      } else {
        return i;
      }
    });
};

export const titleCase = (string) => {
  if (string) {
    return string.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  } else {
    return "";
  }
};
