import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/auth/Login";
import NewCard from "../pages/dash/NewCard";
import Dashboard from "../pages/dash/Dashboard";
import NewBoard from "../pages/dash/NewBoard";
import AllBoards from "../pages/dash/AllBoards";
import AllUsers from "../pages/dash/AllUsers";
import AllCards from "../pages/dash/AllCards";
import NewUser from "../pages/dash/NewUser";
import Directory from "../pages/directory/Directory";
import Edit from "../pages/edit/Edit";
function MyRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/new-card" element={<NewCard />} />
      <Route path="/cards" element={<AllCards />} />
      <Route path="/new-board" element={<NewBoard />} />
      <Route path="/all-boards" element={<AllBoards />} />
      <Route path="/users" element={<AllUsers />} />
      <Route path="/new-user" element={<NewUser />} />
      <Route path="/directory/:dir" element={<Directory />} />
      <Route path="/edit" element={<Edit />} />
      <Route path="*" element={<AllCards />} />
    </Routes>
  );
}
export default MyRoutes;
