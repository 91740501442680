import React, { useEffect, useState } from "react";
import logo from "./adiuvo-logo.png";
import { Link, Navigate, useLocation } from "react-router-dom";
import authService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
function Navigation() {
  const navigate = useNavigate();
  let isAdmin;
  try {
    isAdmin = authService.getCurrentUser().roles.includes("ROLE_ADMIN");
  } catch (err) {
    isAdmin = false;
  }
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };
  useEffect(() => {
    let active = location.pathname.replace("/", "");
    setActiveLink(active);
  }, [location]);
  return (
    <div className="nav-container">
      <div className="nav-logo-container">
        <img src={logo} />
        <button onClick={handleLogout} className="logout btn">
          logout
        </button>
      </div>
      <div className="nav-link-container">
        {isAdmin ? (
          <>
            <Link
              className={`link ${
                activeLink == "new-user" ? "active-link" : null
              }`}
              to="new-user"
            >
              new user
            </Link>
            <Link
              className={`link ${activeLink == "users" ? "active-link" : null}`}
              to="users"
            >
              users
            </Link>
            <Link
              className={`link ${
                activeLink == "new-board" ? "active-link" : null
              }`}
              to="new-board"
            >
              new board
            </Link>
            <Link
              className={`link ${
                activeLink == "all-boards" ? "active-link" : null
              }`}
              to="all-boards"
            >
              boards
            </Link>
          </>
        ) : null}
        <Link
          className={`link ${activeLink == "new-card" ? "active-link" : null}`}
          to="new-card"
        >
          new card
        </Link>
        <Link
          className={`link ${activeLink == "cards" ? "active-link" : null}`}
          to="cards"
        >
          cards
        </Link>
        <Link
          className={`link ${
            activeLink == "board-settings" ? "active-link" : null
          }`}
          to="board-settings"
        >
          settings
        </Link>
      </div>
    </div>
  );
}

export default Navigation;
