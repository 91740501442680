import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputGroup from "../../components/forms/InputGroup";
import authService from "../../services/auth.service";
import "./login.css";
import logo from "./adiuvo-logo.png";
import { Alert } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
function Login() {
  const navigate = useNavigate();
  const initialValues = {
    username: "",
    password: "",
  };
  const [error, setError] = useState("");
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      authService.login(values.username, values.password).then(
        () => {
          navigate("/new-card");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(resMessage);
        }
      );
    },
  });
  return (
    <div className="login-container">
      <div className="login-sidebar">
        <div className="form-container">
          <img className="login-logo" src={logo} />
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className="login-form">
              {error ? (
                <Alert className="w-100" variant="danger">
                  {error}
                </Alert>
              ) : null}
              <InputGroup
                errors={formik.errors}
                touched={formik.touched}
                name="username"
                type="text"
                label="Username"
              />
              <InputGroup
                errors={formik.errors}
                touched={formik.touched}
                type="password"
                name="password"
                label="Password"
              />
              <button className="btn submit-btn login-submit">login</button>
            </form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
}

export default Login;
