import React from "react";
import { Field } from "formik";
import { Row, Col } from "react-bootstrap";
function CheckBox(props) {
  return (
    <div className="my-3 d-flex flex-column">
      <label>Categories</label>
      <div className="w-75 d-flex justify-content-between">
        <div className="group">
          {props.values.map((value, i) => {
            if (i < props.values.length / 2 - 1) {
              return (
                <div key={i} className="form-check">
                  <label className="form-check-label">{value}</label>
                  <Field
                    className="form-check-input"
                    value={value}
                    name={props.name}
                    type="checkbox"
                  ></Field>
                </div>
              );
            }
          })}
        </div>
        <div className="group">
          {props.values.map((value, i) => {
            if (i > props.values.length / 2) {
              return (
                <div key={i} className="form-check ">
                  <label className="form-check-label">{value}</label>
                  <Field
                    className="form-check-input"
                    value={value}
                    name={props.name}
                    type="checkbox"
                  ></Field>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default CheckBox;
