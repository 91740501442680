import React from "react";

function Edit() {
  String.prototype.toAlternatingCase = function () {
    return this.split("")
      .map((e) => (e == e.toUpperCase() ? e.toLowerCase() : e.toUpperCase()))
      .join("");
  };
  return (
    <div className="container">
      <div className="m-auto w-50 shadow-sm my-5 card p-5">
        <p className="h2">Edit / Create</p>
        <form>
          <div className="form-group">
            <label>Name</label>
            <input className="form-control" type="text"></input>
          </div>
          <div className="form-group">
            <label>Category</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          <div className="form-group">
            <label>Assign User</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="form-group">
            <button className="btn btn-success">submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Edit;
