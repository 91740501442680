import React from "react";
import { Table } from "react-bootstrap";
import authService from "../../services/auth.service";
function AllUsersTable(props) {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>#</th>
          <th className="w-50">user</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((u, i) => {
          return (
            <tr key={u.id}>
              <td>{i + 1}</td>
              <td>{u.username}</td>
              <td>
                <button
                  onClick={() => props.handlePass(u.username, u.id)}
                  className="btn-standard table-btn btn"
                >
                  change password
                </button>
              </td>
              <td>
                <button
                  onClick={() => props.handleDelete(u.id)}
                  className="btn-standard table-btn btn"
                >
                  delete
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default AllUsersTable;
