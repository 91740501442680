import React, { useEffect, useState } from "react";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Alert } from "react-bootstrap";
import authService from "../../services/auth.service";
import InputGroup from "./InputGroup";
import userService from "../../services/user.service";
import boardService from "../../services/board.service";

function NewBoardForm(props) {
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState("");
  const [users, setUsers] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [longTermToken, setLongTermToken] = useState("");
  const initialValues = props.initialValues
    ? props.initialValues
    : {
        boardName: "",
        contactName: "",
        phone: "",
        email: "",
        numOfSuites: "",
        playlistKey: "",
        instagram_long_term_code: "",
        cardArea: "",
        accentColor: "",
        userAccess: "",
      };
  const validationSchema = yup.object().shape({
    boardName: yup.string().required("Board name is required"),
    contactName: yup.string().required("Contact name is required"),
    phone: yup.string().required("Phone is required"),
    email: yup.string().required("Email is required"),
    numOfSuites: yup
      .number()
      .typeError("Input must be number")
      .positive("Number must be greater than zero"),
    userAccess: yup.string().required("Please select a user"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      values.instagram_long_term_code = longTermToken;

      if (editMode) {
        console.log("UPODATTEE");
        boardService
          .updateBoard({
            id: editId,
            values,
          })
          .then((res) => {
            formReset();
            window.location.reload();
          });
      } else {
        console.log("NEW BOARD");
        boardService.newBoard(values).then((res) => {
          formReset();
        });
      }
    },
  });
  useEffect(() => {
    if (props.initialValues != undefined) {
      formik.setValues(props.initialValues);
    }
  }, [props.initialValues]);
  useEffect(() => {
    userService.getUsers().then((res) => {
      setUsers(res.data);
    });
  }, []);
  useEffect(() => {
    let found = searchParams.get("code");
    if (found) {
      authService
        .instaCode({
          code: found,
          redirectUrl: "https://localhost:3000/new-board",
        })
        .then((res) => {
          const token = res.data;
          setLongTermToken(token);
        });
    } else {
      localStorage.removeItem("new-board-data");
      localStorage.removeItem("editing");
      localStorage.removeItem("editId");
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("new-board-data")) {
      let existingVals = JSON.parse(localStorage.getItem("new-board-data"));
      formik.setValues(existingVals);
    }
  }, []);
  useEffect(() => {
    if (props.initialValues) {
      localStorage.setItem("editing", "true");
      localStorage.setItem("editId", props.id);
    }
    if (localStorage.getItem("editing")) {
      setEditMode(true);
      setEditId(parseInt(localStorage.getItem("editId")));
    }
  }, [props.initialValues]);
  const formReset = () => {
    formik.handleReset();
    localStorage.removeItem("new-board-data");
  };
  const fetchInstaCode = (e) => {
    e.preventDefault();
    const vals = JSON.stringify(formik.values);
    localStorage.setItem("new-board-data", vals);
    let appId = "589853289156601";
    let redUri = "https://localhost:3000/new-board";
    let url = `https://api.instagram.com/oauth/authorize?client_id=${appId}&redirect_uri=${redUri}&scope=user_profile,user_media&response_type=code`;
    window.open(url, "_blank").focus();
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <h1>{editMode ? "EDITING" : "NEW"} </h1>
        <Row>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              label="Board Name"
              name="boardName"
            />
          </Col>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              label="Contact Name"
              name="contactName"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              label="Phone"
              name="phone"
            />
          </Col>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              label="Email"
              name="email"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              label="Number of Suites"
              name="numOfSuites"
            />
          </Col>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              label="Playlist Key"
              name="playlistKey"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              label="Card Area"
              name="card"
            />
          </Col>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              label="Accent Color"
              name="accentColor"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup
              touched={formik.touched}
              errors={formik.errors}
              type="select"
              selectOptions={users.map((u) => ({
                id: u.id,
                label: u.username,
                value: u.id,
              }))}
              label="User Access"
              name="userAccess"
            />
          </Col>
          <Col>
            <div className="input-group align-items-start flex-column">
              <label>authorize instagram</label>
              <button
                type="button"
                onClick={fetchInstaCode}
                className="btn-standard btn"
              >
                authorize
              </button>
              {longTermToken ? (
                <Alert className="my-3 w-100" variant="success">
                  Authorized
                </Alert>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              {!editMode ? (
                <button type="submit" className="btn new-card-btn btn-standard">
                  create
                </button>
              ) : (
                <button type="submit" className="btn new-card-btn btn-standard">
                  update
                </button>
              )}
            </div>
          </Col>
        </Row>
      </form>
    </FormikProvider>
  );
}

export default NewBoardForm;
