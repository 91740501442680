import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { FaUser, FaPhone, FaEnvelope, FaSearch } from "react-icons/fa";
function AllCardsTable(props) {
  const [cards, setCards] = useState([]);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (props.cards) {
      setCards(props.cards);
    }
  }, [props.cards]);
  const filterList = () => {
    setFilter(search);
  };
  console.log(props.cards);
  const checkMatch = (card, searchFilter) => {
    if (card.business_name) {
      if (
        card.business_name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        card.contact_name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        card.phone.toLowerCase().includes(searchFilter.toLowerCase()) ||
        card.email.toLowerCase().includes(searchFilter.toLowerCase()) ||
        filter == ""
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <>
      <div className="my-4">
        <label className="bold">Search Cards</label>
        <input
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search..."
          value={search}
          onKeyDown={(e) => (e.key == "Enter" ? filterList() : null)}
          className="fancy-input w-100"
          type="text"
        ></input>
        <button onClick={filterList} className="my-2 btn btn-sm btn-standard">
          search
        </button>
      </div>
      <div className="d-flex flex-wrap">
        {cards.map((card) => {
          if (checkMatch(card, filter)) {
            console.log("SHOW");
            return (
              <Card
                className="me-3 mb-2"
                key={card.id}
                style={{ width: "18rem" }}
              >
                <Card.Body>
                  <Card.Title className="alumni">
                    {card.business_name}
                  </Card.Title>

                  <ul className="unstyled-list">
                    <li>
                      <FaUser />
                      <span className="ps-2">{card.contact_name}</span>
                    </li>
                    <li>
                      <FaPhone />
                      <span className="ps-2">{card.phone}</span>
                    </li>
                    <li>
                      <FaEnvelope />
                      <span className="ps-2">{card.email}</span>
                    </li>
                  </ul>
                  <button
                    onClick={() => props.edit(card)}
                    className="btn btn-sm btn-standard"
                  >
                    Edit
                  </button>
                </Card.Body>
              </Card>
            );
          }
        })}
      </div>
    </>
  );
}

export default AllCardsTable;
