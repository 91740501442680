import React from "react";
import { Table } from "react-bootstrap";
function AllBoardsTable(props) {
  function longest(s1, s2) {
    return Array.from(new Set(s1.split("").concat(s2.split("")).sort())).join(
      ""
    );
  }
  return (
    <Table striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Board Name</th>
          <th>User</th>
          <th>Edit</th>
          <th>Cards</th>
        </tr>
      </thead>
      <tbody>
        {props.data
          ? props.data.map((e, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{e.board_name}</td>
                  {e.user ? <td>{e.user.username}</td> : <td></td>}
                  <td>
                    <button
                      onClick={() => props.handleEdit(e.id)}
                      className="btn btn-standard table-btn"
                    >
                      edit
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-standard table-btn">
                      cards
                    </button>
                  </td>
                </tr>
              );
            })
          : null}
      </tbody>
    </Table>
  );
}

export default AllBoardsTable;
