import React from "react";

function Video(props) {
  return (
    <div className={`${props.classes} video-container`}>
      <iframe
        allow="autoplay"
        className="iframe"
        src={`${props.link}?autoplay=1&mute=1&controls=0&showinfo=0&modestbranding=1&autohide=1`}
      />
    </div>
  );
}

export default Video;
