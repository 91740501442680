import React, { useState } from "react";
import { Modal } from "react-bootstrap";
function NewPassword(props) {
  const [newPass, setNewPass] = useState("");
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <div className="generic-modal change-pass-modal">
        <h1 className="generic-modal-header">
          Change password for {props.user}
        </h1>
        <hr />
        <div className="modal-input-group">
          <label>New Password</label>
          <input
            onChange={(e) => setNewPass(e.target.value)}
            type="password"
            className="fancy-input"
          ></input>
        </div>
        <button
          onClick={() => props.confirm(newPass)}
          className="btn btn-standard"
        >
          confirm
        </button>
      </div>
    </Modal>
  );
}

export default NewPassword;
