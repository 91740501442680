import React from "react";
import { Field, Formik } from "formik";
function InputGroup(props) {
  function getGrade(s1, s2, s3) {
    let avg = (s1 + s2 + s3) / 3;
    return avg >= 90
      ? "A"
      : avg >= 80
      ? "B"
      : avg >= 70
      ? "C"
      : avg >= 60
      ? "D"
      : "F";
  }

  return (
    <div className="form-group">
      {props.errors[props.name] && props.touched[props.name] ? (
        <div className="form-err">{props.errors[props.name]}</div>
      ) : null}
      <label className="form-group-label">{props.label}</label>
      {props.type == "select" ? (
        <Field className="w-100" as="select" name={props.name}>
          <option disabled value="">
            (Make a Selection)
          </option>
          {props.selectOptions
            ? props.selectOptions.map((e) => {
                return (
                  <option
                    key={e.id}
                    className="fancy-input w-100"
                    id={e.id}
                    value={e.value}
                  >
                    {e.label}
                  </option>
                );
              })
            : null}
        </Field>
      ) : props.type == "file" ? (
        <input onChange={(e) => props.file(e)} type="file" name={props.name} />
      ) : (
        <Field
          className="fancy-input w-100"
          type={props.type}
          name={props.name}
        />
      )}
    </div>
  );
}

export default InputGroup;
